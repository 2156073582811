"use client";

import React from "react";
import { Box, BoxProps } from "@mui/material";

import {
  TopicDashboardInvestigateMap,
  TopicDashboardSubsectionTypeConfig,
  TopicInvestigateMapProps
} from "../util/elementHelpers/dashboard/types";

import { DEFAULT_COLUMN_SPACING } from "layout/configuration";
import { TopicSectionDescription } from "../util/elementHelpers/ClimateChange/vulnerability/sectionDescriptionDictionary";
import { StandardMapDataTableRow } from "common/components/InvestigateMap/V2/WithDataTable/util/geoJsonToDataForSelectedStat";
import { useIsMobile } from "common/util/hooks/useIsMobile";

import { ContentCardSubSection } from "layout/card/ContentCardSubSection";
import { ContentCardTextBlock } from "layout/card/ContentCardTextBlock";
import { InvestigateMapBaseWrapper } from "common/components/InvestigateMap/InvestigateMapBaseWrapper";
import { InvestigateMap } from "common/components/InvestigateMap/V2/InvestigateMap";
import { InvestigateMapWithDataTableProps } from "common/components/InvestigateMap/V2/WithDataTable/InvestigateMapWithDataTable";
import { topicDashboardAboutTheData } from "./TopicDashboardData";

type TopicDashboardMapProps = {
  map?: TopicDashboardInvestigateMap | null;
  mapV2?: TopicDashboardSubsectionTypeConfig &
    InvestigateMapWithDataTableProps<StandardMapDataTableRow>;
  subSectionProps: {
    sx: BoxProps["sx"];
  };
  description?: TopicSectionDescription;
  topicName?: string;
};

const TopicDashboardMap: React.FC<TopicDashboardMapProps> = ({
  map,
  mapV2,
  subSectionProps,
  description,
  topicName
}) => {
  const isMobile = useIsMobile();
  return (
    <ContentCardSubSection
      {...subSectionProps}
      sx={{ mb: 0, display: "flex", flexDirection: "column", gap: 2 }}
    >
      {map && (
        <>
          <InvestigateMapBaseWrapper
            {...(map.props as TopicInvestigateMapProps)}
            sortInvestigations={false}
            displayDirection="column"
            hideGeographyGranularitySelector={true}
            infoElement={
              <Box>
                {(description && description("map")) ?? null}
                <ContentCardTextBlock
                  title="Changing the map"
                  sx={{ my: 4 }}
                  titleProps={{ fontWeight: 700, variant: "h5" }}
                >
                  Using the toggles below select the land cover indicator type that you would like
                  to see displayed on the map.
                </ContentCardTextBlock>
              </Box>
            }
          />
          {topicDashboardAboutTheData({
            section: map,
            uiLocation: `${topicName ?? ""} investigate map`.trim()
          })}
        </>
      )}
      {mapV2 && (
        <InvestigateMap
          {...mapV2}
          containerSpacing={DEFAULT_COLUMN_SPACING}
          layout={isMobile ? "vertical" : "horizontal"}
          mapHeight={isMobile ? undefined : "700px"}
          preInvestigationContent={
            <Box>
              {(description && description("map")) ?? null}
              <ContentCardTextBlock
                title="Changing the map"
                sx={{ my: 4 }}
                titleProps={{ fontWeight: 700, variant: "h5" }}
              >
                Using the toggles below select the land cover indicator type that you would like to
                see displayed on the map.
              </ContentCardTextBlock>
            </Box>
          }
        />
      )}
    </ContentCardSubSection>
  );
};

export default TopicDashboardMap;
