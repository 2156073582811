"use client";

import { useCallback, useMemo } from "react";
import { Box, Stack } from "@mui/material";
import isNil from "lodash/isNil";
import { StyleFunction } from "leaflet";

import { InvestigateMapPropsV2 } from "./util/types";
import { MhcGeographyEnum, MhcGeoJsonFeatureProperty } from "graphqlApi/types";

import { addInfoBoxToProperties } from "../../LocationSwitcher/util/groupAndTurnLocationsIntoFeatures";
import useMapState from "./util/useMapState";
import { useMapType } from "./util/useMapType";
import { getReadableGeographyName } from "common/util/geographyHelpers";
import { useIsMobile } from "common/util/hooks/useIsMobile";

import { InvestigateDropdown } from "../../charts/Investigate/InvestigateDropdown";
import { GeoMap, GeoMapProps } from "../../GeoMap";
import {
  basicPolygonFeatureStyle,
  noDataMapColor,
  polygonFeatureWithoutDataStyle,
  selectedPolygonFeatureStyle
} from "../../GeoMap/mapStyles";
import { InfoIcon } from "../../InfoIcon";
import { InvestigateMapLegendWrapperV2 } from "./InvestigateMapLegendWrapperV2";

export const InvestigateMap: React.FC<InvestigateMapPropsV2> = ({
  allowGeographyChange = true,
  colorRangeName,
  containerSpacing = 4,
  customInvestigationLabel,
  defaultGeography,
  defaultStatId,
  initialGeoJsonByGeography,
  initialMinMaxRecord,
  layout = "vertical",
  mapHeight = "100%",
  omitGeographies,
  onGeoJsonByGeographyChange,
  onMapCreated,
  onSelectedGeographyChange,
  onSelectedIdChange,
  onSelectedStatChange,
  onSelectedStatIdChange,
  overrideDateByStatMap,
  overrideSelectedGeography,
  overrideSelectedId,
  overrideSetSelectedGeography,
  overrideSetSelectedId,
  postInvestigationContent,
  preInvestigationContent,
  stackInvestigations,
  statConfigs,
  stats,
  investigateDropdownSx
}) => {
  const isMobile = useIsMobile();
  const {
    minMaxRecord,
    setMinMaxRecord,
    selectedGeoJson,
    setSelectedGeoJson,
    geoJsonByGeography,
    setGeoJsonByGeography,
    loadingGeography,
    setLoadingGeography,
    selectedLocationId,
    setSelectedLocationId
  } = useMapState({
    defaultGeography,
    initialGeoJsonByGeography,
    initialMinMaxRecord,
    onGeoJsonByGeographyChange,
    onSelectedIdChange,
    overrideSelectedId,
    overrideSetSelectedId
  });

  const {
    handleGeographyChange,
    handleStatIdChange,
    mappedOptions,
    selectedGeography,
    selectedStatId,
    siMappedOptions,
    statIds
  } = useMapType({
    allowGeographyChange,
    defaultGeography,
    defaultStatId,
    geoJsonByGeography,
    initialMinMax: minMaxRecord,
    loadingGeography,
    omitGeographies,
    onSelectedGeographyChange,
    onSelectedStatChange,
    onSelectedStatIdChange,
    overrideDateByStatMap,
    overrideSelectedGeography,
    overrideSetSelectedGeography,
    selectedLocationId,
    setGeoJsonByGeography,
    setLoadingGeography,
    setMinMaxRecord,
    setSelectedGeoJson,
    statConfigs,
    stats
  });

  const layoutIsHorizontal = layout === "horizontal";

  const featureStyle: GeoMapProps["featureStyle"] = useCallback(
    (feature: Parameters<StyleFunction<MhcGeoJsonFeatureProperty>>[0]) => {
      const properties = feature?.properties as ReturnType<
        typeof addInfoBoxToProperties
      >[0]["properties"];
      const colors = properties.colors;
      if (colors) {
        const colorForStatId = colors[selectedStatId];
        const base = {
          ...(properties.id === selectedLocationId
            ? selectedPolygonFeatureStyle
            : basicPolygonFeatureStyle),
          fillColor: colorForStatId ?? noDataMapColor
        };
        if (properties.id !== selectedLocationId) {
          base["fillColor"] = colorForStatId ?? noDataMapColor;
        }
        return base;
      }
      return polygonFeatureWithoutDataStyle;
    },
    [selectedStatId, selectedLocationId]
  );

  const legend = useMemo(() => {
    const filtered = stats.filter((stat) => stat.id === selectedStatId);
    const selectedSi = filtered.length > 0 ? filtered[0] : null;
    if (isNil(selectedSi)) return;
    const minMaxByStat = minMaxRecord[selectedSi.id];
    if (isNil(minMaxByStat)) return;
    const minMax = minMaxByStat[selectedGeography ?? "state"];
    if (isNil(minMax)) return;
    return (
      <InvestigateMapLegendWrapperV2
        colorRangeName={colorRangeName}
        selectedSi={selectedSi}
        minMax={minMax}
      />
    );
  }, [colorRangeName, minMaxRecord, selectedGeography, selectedStatId, stats]);

  return (
    <Stack
      gap={containerSpacing}
      flex={1}
      data-testid="investigate-map-wrapper"
      direction={layoutIsHorizontal ? "row" : "column"}
      position="relative"
    >
      <Box gap={2} sx={{ width: layoutIsHorizontal ? "50%" : "100%" }} flexGrow={0}>
        {preInvestigationContent}
        <Stack
          gap={2}
          direction={isMobile || (stackInvestigations ?? !layoutIsHorizontal) ? "column" : "row"}
          flex="1"
          flexWrap="wrap"
          width="100%"
          display="flex"
        >
          {allowGeographyChange && (
            <InvestigateDropdown
              sx={investigateDropdownSx ?? { width: isMobile ? "100%" : "50%", flex: 1 }}
              value={
                selectedGeography ? getReadableGeographyName(selectedGeography, true) : "state"
              }
              title={
                <Stack direction="row" gap={1}>
                  Select Map Type
                  <InfoIcon variant="standard" sx={{ my: "auto" }}>
                    Options grayed-out are not available for the selected investigation.
                  </InfoIcon>
                </Stack>
              }
              onChange={handleGeographyChange}
              defaultValue={defaultGeography}
              options={mappedOptions}
              type="Map"
              valueItemIsSelected={({ value, selectedValue }) => {
                if (!value || !selectedValue) return false;
                return selectedValue === getReadableGeographyName(value as MhcGeographyEnum, true);
              }}
              disabled={loadingGeography}
            />
          )}
          {statIds.length > 1 && (
            <InvestigateDropdown
              sx={
                investigateDropdownSx ?? {
                  width: isMobile ? "100%" : "50%",
                  maxWidth: "100%",
                  flex: 1
                }
              }
              value={
                selectedStatId
                  ? siMappedOptions.find(({ value = "" }) => value === selectedStatId)?.title ??
                    selectedStatId
                  : defaultStatId
              }
              title={
                <Stack direction="row" gap={1}>
                  {customInvestigationLabel ?? "Select Investigation"}
                  <InfoIcon variant="standard" sx={{ my: "auto" }}>
                    Investigations grayed-out are not available for the selected map type.
                  </InfoIcon>
                </Stack>
              }
              onChange={handleStatIdChange}
              defaultValue={defaultStatId}
              options={siMappedOptions}
              type="Map"
              valueItemIsSelected={({ value, selectedValue }) => {
                if (!value || !selectedValue) return false;
                return selectedValue === value;
              }}
              disabled={loadingGeography}
              disableRule={(value) => {
                const stat = stats.filter((stat) => stat.id === value);
                const availableGeos = stat.length > 0 ? stat[0]?.availableGeographies : [];
                return selectedGeography
                  ? !availableGeos?.includes(selectedGeography) ?? false
                  : false;
              }}
            />
          )}
        </Stack>
        {postInvestigationContent}
      </Box>
      {selectedGeoJson && (
        <Box gap={2} sx={{ width: layoutIsHorizontal ? "50%" : "100%" }} flexGrow={1}>
          <GeoMap
            forceLoading={loadingGeography}
            geoJSON={selectedGeoJson}
            height={mapHeight}
            showInfoBox
            selectedFeatureId={selectedLocationId}
            onFeatureClick={setSelectedLocationId}
            selectedDataIdentifier={selectedStatId}
            zoomToFeature
            animateZoom
            featureStyle={featureStyle}
            maxZoom={13}
            legend={legend}
            onMapCreated={onMapCreated}
          />
        </Box>
      )}
    </Stack>
  );
};
