import isNil from "lodash/isNil";

import { MhcGeographyEnum, MhcLocationStatQueryConfig, MhcMapMaxMinValues } from "graphqlApi/types";
import { IdentifierConfig } from "modules/Topics/util/elementHelpers/dashboard/types";

type IdentifierConfigsToMapLayerConfigs = {
  configs: IdentifierConfig[];
  locationId?: string;
};
export const identifierConfigsToMapLayerConfigs = ({
  configs,
  locationId
}: IdentifierConfigsToMapLayerConfigs): MhcLocationStatQueryConfig[] => {
  if (isNil(configs) || configs.length == 0) return [];
  return configs?.map(({ endsOn, startsOn, identifier, forLocationId, granularity }) => ({
    date: endsOn,
    endDate: endsOn,
    startDate: startsOn,
    identifier,
    locationId: forLocationId ?? locationId,
    granularity
  }));
};

type ConvertLegendValuesToMinMax = {
  maxMinValues: MhcMapMaxMinValues[];
  geography: MhcGeographyEnum;
};
type ConvertedDict = Record<
  string,
  Record<MhcGeographyEnum, { minValue: number; maxValue: number }>
>;
export const convertLegendValuesToMinMax = ({
  maxMinValues,
  geography
}: ConvertLegendValuesToMinMax) => {
  return maxMinValues?.reduce((dict, { identifier, min, max }) => {
    // TODO: Resolve this type error
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    dict[identifier] = {
      [geography]: {
        minValue: min,
        maxValue: max
      }
    };
    return dict;
  }, {} as ConvertedDict);
};
