import first from "lodash/first";
import isNil from "lodash/isNil";
import sortBy from "lodash/sortBy";
import { add } from "date-fns/add";
import { endOfMonth } from "date-fns/endOfMonth";
import { isWithinInterval } from "date-fns/isWithinInterval";
import { startOfMonth } from "date-fns/startOfMonth";
import { sub } from "date-fns/sub";

import { MhcSeason, MhcTimeSeriesGranularityEnum } from "graphqlApi/types";

import { dateToIsoString } from "common/util/date";

import { getUtcDateFromString, getUtcDateNumber, normalizeDate } from "./utcDateFromString";

export const SEASON_ALL_TIME_SLUG = "all";

/**
 * Sorts seasons by `endDate`
 *
 * @param seasons
 *
 * @returns Array of sorted seasons by endDate
 */
export const sortSeasons = (seasons: MhcSeason[]) =>
  sortBy(seasons, ({ endDate }) => (getUtcDateNumber(endDate) as number) * -1);

/**
 * Get the slug (used to construct url path) of a given season
 *
 * @param season
 *
 * @returns string slug (usually start and end year)
 */
export const getSeasonSlug = (season: MhcSeason) => {
  if (season.slug === SEASON_ALL_TIME_SLUG) return SEASON_ALL_TIME_SLUG;
  return `${season.startDate?.split("-")[0] as string}-${season.endDate?.split("-")[0] as string}`;
};

/**
 * Finds the current season based on current date
 *
 * @param seasons
 *
 * @returns season
 */
export const getCurrentSeason = (seasons?: MhcSeason[]) => {
  if (!seasons) return null;
  const seasonBasedOnDates = seasons.find((season) => {
    const start = getUtcDateFromString(season.startDate);
    const end = getUtcDateFromString(season.endDate);
    if (!isNil(start) && !isNil(end)) {
      return isWithinInterval(new Date(), { start, end });
    }
    return false;
  });
  if (seasonBasedOnDates) {
    return seasonBasedOnDates;
  }
  return sortSeasons(seasons)[0] as MhcSeason;
};

export const isCurrentSeason = (season: MhcSeason | null, seasons: MhcSeason[]): boolean => {
  const current = getCurrentSeason(seasons);
  if (!current || !season) return false;
  return current.slug === season.slug;
};

type GetDateSeriesOfSeason = {
  season?: MhcSeason;
  granularity: MhcTimeSeriesGranularityEnum;
  startDate?: string;
  endDate?: string;
};
/**
 * Returns an array of dates for a given season or a start/end date range
 *
 * @param params
 * @param params.season
 * @param params.granularity
 * @param params.startDate
 * @param params.endDate
 *
 * @returns array of date objects
 */
export const getDateSeriesOfSeason = ({
  season,
  granularity,
  startDate,
  endDate
}: GetDateSeriesOfSeason) => {
  const { startDate: seasonStartDate, endDate: seasonEndDate } = season || {};
  const _startDate = startDate ?? seasonStartDate;
  const _endDate = endDate ?? seasonEndDate;
  if (!_startDate || !_endDate) return [];
  let start = new Date(_startDate);
  if (granularity === MhcTimeSeriesGranularityEnum.Month) {
    start = startOfMonth(start);
  }
  const end = new Date(_endDate);
  const dateSeries = [];
  while (start <= end) {
    dateSeries.push(start);
    start = add(start, { [`${granularity}s`]: 1 });
  }
  return dateSeries;
};

export const seasonIsAllTime = (season: MhcSeason | null) => season?.slug === SEASON_ALL_TIME_SLUG;

/**
 * Adds a bit more context to season title/name
 *
 * @param season
 *
 * @returns String with additional copy/text
 */
export const getFullSeasonName = (season: MhcSeason | null) => {
  if (!season) return "";
  if (seasonIsAllTime(season)) {
    return "All Time";
  }
  return `Fall and Winter Virus Season ${getSeasonSlug(season)}`;
};

/**
 * Add "all time" season based on start of first season and end of last
 *
 * @param seasons - list of seasons
 *
 * @returns list of seasons with all time season
 */
export const addAllTimeAsSeason = (seasons: MhcSeason[]): MhcSeason[] => {
  const startDate = seasons[0]?.startDate;
  const endDate = seasons[seasons.length - 1]?.endDate;

  const slug = getSeasonSlug({ startDate, endDate });
  const allTimeSeason: MhcSeason = {
    __typename: "MhcSeason",
    endDate,
    groupName: "COVID19",
    name: `All Time (${slug})`,
    slug: SEASON_ALL_TIME_SLUG,
    startDate
  };
  return [...seasons, allTimeSeason];
};

/**
 * Finds the season that matches a string slug from url path ("YYYY-YYYY")
 *
 * @param slug - string from url path ("YYYY-YYYY")
 * @param seasons - list of seasons
 *
 * @returns season
 */
export const getSeasonFromSlug = (slug: string, seasons: MhcSeason[]): MhcSeason =>
  seasons.find((season) => slug === getSeasonSlug(season)) as MhcSeason;

export const getFirstSeason = (seasons: MhcSeason[]) =>
  first(seasons.filter(({ slug }) => slug !== SEASON_ALL_TIME_SLUG));

/**
 * Returns the slug of the current season based on current date rather than season query
 *
 * @returns season slug as string
 */
export const getCurrentSeasonSlug = () => {
  const date = normalizeDate(new Date());
  const currentYear = date.getFullYear();
  return `${currentYear - 1}-${currentYear}`;
};

/**
 * Returns last day of full month within a season
 *
 * @param seasonEndDate - date as string
 * @param months - duration
 *
 * @returns date as ISO string
 */
export const seasonEndsOn = (seasonEndDate?: string | null, months = 1) => {
  if (!seasonEndDate) return null;
  const utcDate = getUtcDateFromString(seasonEndDate);
  if (isNil(utcDate)) return null;
  const date = normalizeDate(utcDate);
  return dateToIsoString(endOfMonth(sub(date, { months: months })));
};
