"use client";

import { useCallback, useState } from "react";

import { InvestigateMapPropsV2 } from "./types";
import { MhcFeatureCollectionFragment } from "graphqlApi/types";

import { MinMaxFeaturesProps } from "./updateMinMaxFromFeature";
import { useLocalOrOverride } from "./useLocalOverride";

type MapStateHooksParams = Pick<
  InvestigateMapPropsV2<MhcFeatureCollectionFragment>,
  | "defaultGeography"
  | "initialGeoJsonByGeography"
  | "initialMinMaxRecord"
  | "onSelectedIdChange"
  | "overrideSelectedId"
  | "overrideSetSelectedId"
  | "onGeoJsonByGeographyChange"
>;
const useMapState = ({
  defaultGeography,
  initialGeoJsonByGeography,
  initialMinMaxRecord,
  onGeoJsonByGeographyChange,
  onSelectedIdChange,
  overrideSelectedId,
  overrideSetSelectedId
}: MapStateHooksParams) => {
  const [selectedLocationId, setSelectedLocationId] = useLocalOrOverride<string | undefined>({
    overrideSetValue: overrideSetSelectedId,
    overrideValue: overrideSelectedId
  });
  const [loadingGeography, setLoadingGeography] = useLocalOrOverride<boolean>({
    defaultValue: false
  });
  const [geoJsonByGeography, setGeoJsonByGeography] = useState<
    InvestigateMapPropsV2<MhcFeatureCollectionFragment>["initialGeoJsonByGeography"] | undefined
  >(initialGeoJsonByGeography);
  const [selectedGeoJson, setSelectedGeoJson] = useState<MhcFeatureCollectionFragment | undefined>(
    initialGeoJsonByGeography[defaultGeography]
  );
  const [minMaxRecord, setMinMaxRecord] =
    useState<MinMaxFeaturesProps["initial"]>(initialMinMaxRecord);

  const updateSelectedLocationId = useCallback(
    (selectedId: string | undefined) => {
      onSelectedIdChange?.(selectedId);
      setSelectedLocationId(selectedId);
    },
    [onSelectedIdChange, setSelectedLocationId]
  );

  const updateGeoJsonByGeography = useCallback(
    (
      geoJsonByGeography: InvestigateMapPropsV2<MhcFeatureCollectionFragment>["initialGeoJsonByGeography"]
    ) => {
      onGeoJsonByGeographyChange?.(geoJsonByGeography);
      setGeoJsonByGeography(geoJsonByGeography);
    },
    [onGeoJsonByGeographyChange]
  );

  return {
    minMaxRecord,
    setMinMaxRecord,
    selectedGeoJson,
    setSelectedGeoJson,
    geoJsonByGeography,
    setGeoJsonByGeography: updateGeoJsonByGeography,
    loadingGeography,
    setLoadingGeography,
    selectedLocationId,
    setSelectedLocationId: updateSelectedLocationId
  };
};

export default useMapState;
