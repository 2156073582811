import isNil from "lodash/isNil";

import {
  TopicDashboardDataGroup,
  TopicDashboardMapSectionV2Config
} from "../elementHelpers/dashboard/types";
import { MhcAttributionFragment } from "graphqlApi/types";

import { DEFAULT_MAP_COLOR_RANGE_NAME } from "common/components/GeoMap/utils";
import { createInvestigateMapProps } from "common/components/InvestigateMap/V2/util/createInvestigateMapProps";
import { getReadableGeographyName } from "common/util/geographyHelpers";
import { LoadedLocationStatDictionaryByLocation } from "modules/Topics/util/fetchingFunctions/fetchStatsForAllSections";
import { createLoadedStatDictionary } from "modules/Topics/util/fetchingFunctions/util";

import { KpiProps } from "common/components/KPI";
import { fetchKpiGroupProps } from "./kpi/fetchKpiGroupProps";

const removeAdditionalInfoFromKpis = (kpis: KpiProps[]) => {
  return kpis.map((kpi) => {
    return {
      ...kpi,
      additionalInformation: null,
      percentSentenceProps: null
    };
  });
};

export type FetchMapDataV2Params = TopicDashboardMapSectionV2Config;

export const fetchMapDataV2 = async ({
  availableGeographies,
  omitGeographies,
  stats,
  kpiGroupConfig,
  defaultGeography,
  overrideDateByStatMap,
  allowGeographyChange = true,
  customInvestigationLabel = null,
  colorRangeName = DEFAULT_MAP_COLOR_RANGE_NAME
}: FetchMapDataV2Params): Promise<TopicDashboardDataGroup | null> => {
  const investigateMapProps = await createInvestigateMapProps({
    availableGeographies,
    omitGeographies,
    stats,
    defaultGeography,
    overrideDateByStatMap,
    colorRangeName
  });

  const locationId = "state";
  let loadedStatDictionary: LoadedLocationStatDictionaryByLocation | undefined;
  const statIdConfig = kpiGroupConfig?.statIdConfigs?.[0];
  if (statIdConfig) {
    loadedStatDictionary = await createLoadedStatDictionary({
      identifierConfigs: [statIdConfig],
      locationId
    });
  }
  const kpiGroup =
    kpiGroupConfig &&
    (await fetchKpiGroupProps({
      loadedStats: loadedStatDictionary ? loadedStatDictionary : undefined,
      kpiGroupConfig,
      locationGeography: locationId,
      locationId,
      locationName: getReadableGeographyName(locationId)
    }));

  if (isNil(investigateMapProps)) return null;

  return {
    id: "investigate-map-v2-section",
    ...investigateMapProps,
    allowGeographyChange,
    customInvestigationLabel,
    colorRangeName,
    kpis: removeAdditionalInfoFromKpis(kpiGroup?.kpis ?? []),
    attributions: [
      ...investigateMapProps.stats.flatMap((stat) => stat.attributions),
      ...(kpiGroup?.attributions ?? [])
    ] as MhcAttributionFragment[]
  };
};
