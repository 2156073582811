"use client";

import { Dispatch, SetStateAction, useCallback, useEffect, useMemo } from "react";
import isNil from "lodash/isNil";
import { getMhcMapDataWithStats } from "graphqlApi/legacy/mhcClient";

import { MappedDropdownOption } from "common/components/charts/Investigate/types";
import { InvestigateMapPropsV2 } from "common/components/InvestigateMap/V2/util/types";
import {
  MhcFeatureCollection,
  MhcGeographyEnum,
  MhcLocation,
  MhcStatIdentifier
} from "graphqlApi/types";

import {
  groupAndTurnLocationsIntoFeatures,
  NoCountry
} from "common/components/LocationSwitcher/util/groupAndTurnLocationsIntoFeatures";
import { getReadableGeographyName } from "common/util/geographyHelpers";
import { sortGeographiesBySize } from "common/util/sortGeographiesBySize";

import { MinMaxFeaturesProps } from "./updateMinMaxFromFeature";
import { useLocalOrOverride } from "./useLocalOverride";
import { useSiInvestigations } from "./useSiInvestigations";

interface UseMapTypeProps {
  allowGeographyChange?: boolean;
  defaultGeography: MhcGeographyEnum;
  defaultStatId: string;
  geoJsonByGeography?: InvestigateMapPropsV2["initialGeoJsonByGeography"] | undefined;
  initialMinMax: MinMaxFeaturesProps["initial"];
  loadingGeography?: boolean;
  omitGeographies?: InvestigateMapPropsV2["omitGeographies"];
  onSelectedGeographyChange: InvestigateMapPropsV2["onSelectedGeographyChange"];
  onSelectedStatChange: InvestigateMapPropsV2["onSelectedStatChange"];
  onSelectedStatIdChange: InvestigateMapPropsV2["onSelectedStatIdChange"];
  overrideDateByStatMap?: InvestigateMapPropsV2["overrideDateByStatMap"];
  overrideSelectedGeography: InvestigateMapPropsV2["overrideSelectedGeography"];
  overrideSetSelectedGeography: InvestigateMapPropsV2["overrideSetSelectedGeography"];
  selectedLocationId?: string;
  setGeoJsonByGeography: (
    geoJsonByGeography: Partial<Record<MhcGeographyEnum, MhcFeatureCollection>>
  ) => void;
  setLoadingGeography: (nv: boolean) => void;
  setMinMaxRecord: (nv: MinMaxFeaturesProps["initial"]) => void;
  setSelectedGeoJson: Dispatch<SetStateAction<MhcFeatureCollection | undefined>>;
  statConfigs?: InvestigateMapPropsV2["statConfigs"];
  stats: MhcStatIdentifier[];
}

interface HandleDataFetchingOnUpdateProps {
  geo: NoCountry;
  geoJsonByGeography?: InvestigateMapPropsV2["initialGeoJsonByGeography"] | undefined;
  initialMinMax: UseMapTypeProps["initialMinMax"];
  loadingGeography: UseMapTypeProps["loadingGeography"];
  overrideDateByStatMap?: UseMapTypeProps["overrideDateByStatMap"];
  selectedLocationId: UseMapTypeProps["selectedLocationId"];
  selectedStatId: string;
  setGeoJsonByGeography: UseMapTypeProps["setGeoJsonByGeography"];
  setLoadingGeography: UseMapTypeProps["setLoadingGeography"];
  setMinMaxRecord: UseMapTypeProps["setMinMaxRecord"];
  setSelectedGeoJson: UseMapTypeProps["setSelectedGeoJson"];
  statConfigs?: InvestigateMapPropsV2["statConfigs"];
  statIds: string[];
}

export const useMapType = ({
  allowGeographyChange = true,
  defaultGeography,
  defaultStatId,
  geoJsonByGeography,
  initialMinMax,
  loadingGeography,
  omitGeographies,
  onSelectedGeographyChange,
  onSelectedStatChange,
  onSelectedStatIdChange,
  overrideDateByStatMap,
  overrideSelectedGeography,
  overrideSetSelectedGeography,
  selectedLocationId,
  setGeoJsonByGeography,
  setLoadingGeography,
  setMinMaxRecord,
  setSelectedGeoJson,
  statConfigs,
  stats
}: UseMapTypeProps) => {
  const [selectedGeography, setSelectedGeography] = useLocalOrOverride<MhcGeographyEnum>({
    defaultValue: defaultGeography,
    overrideSetValue: overrideSetSelectedGeography,
    overrideValue: overrideSelectedGeography
  });
  const {
    ids: statIds,
    selectedStatId,
    mappedOptions: siMappedOptions,
    setSelectedStatId
  } = useSiInvestigations({
    stats,
    defaultStatId,
    onSelectedStatIdChange
  });

  const selectedStat = useMemo(() => {
    const filtered = stats.filter((stat) => stat.id === selectedStatId);
    const selectedSi = filtered.length > 0 ? filtered[0] : null;
    onSelectedStatChange?.(selectedSi ?? undefined);
    return selectedSi;
  }, [onSelectedStatChange, selectedStatId, stats]);

  const availableGeographies = useMemo(() => {
    return [...new Set(stats.flatMap((stat) => stat.availableGeographies))];
  }, [stats]);

  const mappedOptions: MappedDropdownOption[] = useMemo(() => {
    if (!allowGeographyChange) return [];
    const geos = availableGeographies.filter((geo) =>
      omitGeographies ? !omitGeographies?.includes(geo) : true
    );
    return sortGeographiesBySize(geos)
      .map((geography) => {
        return {
          title: getReadableGeographyName(geography, true),
          value: geography
        };
      })
      .reverse();
  }, [availableGeographies, omitGeographies, allowGeographyChange]);

  const handleDataFetchingOnUpdate = useCallback(
    ({
      geo,
      geoJsonByGeography,
      initialMinMax,
      loadingGeography,
      overrideDateByStatMap,
      selectedLocationId,
      selectedStatId,
      statIds,
      setGeoJsonByGeography,
      setLoadingGeography,
      setMinMaxRecord,
      setSelectedGeoJson,
      statConfigs
    }: HandleDataFetchingOnUpdateProps) => {
      if (loadingGeography === true) return;
      if (!isNil(geoJsonByGeography)) {
        if (geoJsonByGeography && !isNil(geoJsonByGeography[geo])) {
          setSelectedGeoJson(geoJsonByGeography[geo]);
          return;
        }
      }
      // TODO: Setup a way to cache geographies and mapData?
      // const geoJson = geoJsonByGeography[geo];
      // if (geoJson?.features.length !== 0 && geoJson) {
      //   setSelectedGeoJson(geoJson);
      //   return;
      // }
      setLoadingGeography(true);
      const loadNewGeography = async () => {
        if (loadingGeography) return;
        let locations = [];
        const statConfig = statConfigs?.find(({ identifier }) => identifier === selectedStatId);
        locations = await getMhcMapDataWithStats({
          geographies: [geo],
          statIds: statIds,
          startsOn: statConfig?.startsOn,
          endsOn: statConfig?.endsOn
        });
        const { featureMap: mappedFeatures, minMaxByStat } = groupAndTurnLocationsIntoFeatures({
          locations: locations as MhcLocation[],
          selectedLocationId: selectedLocationId,
          addColor: true,
          initialMinMax: initialMinMax,
          overrideDateByStatMap
        });
        setMinMaxRecord(minMaxByStat);
        setSelectedGeoJson(mappedFeatures[geo]);
        const current = { ...geoJsonByGeography };
        delete current[geo];
        current[geo] = mappedFeatures[geo];
        setGeoJsonByGeography(current);
      };
      void loadNewGeography().then(() => setLoadingGeography(false));
    },
    []
  );

  const handleGeographyUpdate = (geo: NoCountry, statId: string) => {
    onSelectedGeographyChange?.(geo);
    setSelectedGeography(geo);
    const stat = stats.find((stat) => stat.id === statId);
    if (!stat?.availableGeographies.includes(geo)) {
      setSelectedStatId(
        stats.find((stat) => stat.availableGeographies.includes(geo))?.id ?? statId
      );
    } else setSelectedStatId(statId);
    handleDataFetchingOnUpdate({
      geo,
      geoJsonByGeography,
      initialMinMax,
      loadingGeography,
      overrideDateByStatMap,
      selectedLocationId,
      selectedStatId: statId,
      statIds,
      setGeoJsonByGeography,
      setLoadingGeography,
      setMinMaxRecord,
      setSelectedGeoJson,
      statConfigs
    });
  };

  const handleGeographyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const geo = event.target.value as NoCountry;
    handleGeographyUpdate(geo, selectedStatId);
  };

  const handleStatIdChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const statId = event.target.value as string;
    handleGeographyUpdate(selectedGeography as NoCountry, statId);
  };

  useEffect(() => {
    if (isNil(overrideSelectedGeography) || loadingGeography) return;
    handleDataFetchingOnUpdate({
      geo: overrideSelectedGeography as NoCountry,
      statIds,
      geoJsonByGeography,
      initialMinMax,
      loadingGeography,
      overrideDateByStatMap,
      selectedLocationId,
      statConfigs,
      selectedStatId,
      setGeoJsonByGeography,
      setLoadingGeography,
      setMinMaxRecord,
      setSelectedGeoJson
    });
  }, [
    geoJsonByGeography,
    handleDataFetchingOnUpdate,
    initialMinMax,
    loadingGeography,
    overrideDateByStatMap,
    overrideSelectedGeography,
    selectedLocationId,
    selectedStatId,
    statConfigs,
    setGeoJsonByGeography,
    setLoadingGeography,
    setMinMaxRecord,
    setSelectedGeoJson,
    stats,
    statIds
  ]);

  return {
    mappedOptions,
    selectedGeography: overrideSelectedGeography ? overrideSelectedGeography : selectedGeography,
    handleGeographyUpdate,
    handleGeographyChange,
    selectedStatId,
    handleStatIdChange,
    statIds,
    siMappedOptions,
    selectedStat
  };
};
